
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        











































































































































@import '@/styles/_colors.scss';

.header {
  position: sticky;
  top: 0;
  z-index: 20;
  color: var(--color-text-secondary);
  background: var(--color-primary);
}

.container {
  @include container();
  position: relative;
  display: flex;
  // flex-direction: row-reverse;
  align-items: center;
  height: 4rem;

  @media screen and (min-width: bp(m)) {
    flex-direction: row;
  }
}

.logo {
  position: relative;
  display: none;
  width: span(2);
  height: 100%;
  padding: 0.65rem 0;

  @media screen and (min-width: bp(s)) {
    display: block;
  }

  @media screen and (min-width: bp(m)) {
    width: span(3);
  }

  @media screen and (min-width: bp(l)) {
    width: span(2);
  }
}

.logo__img {
  display: block;
  height: 100%;
}

.title {
  width: span(9);

  @media screen and (min-width: bp(s)) {
    width: span(7);
    margin-left: span(0, 1);
  }

  @media screen and (min-width: bp(m)) {
    width: span(6);
  }

  @media screen and (min-width: bp(l)) {
    width: span(7);
  }
}

.title__name {
  font-weight: 500;
  font-size: 1.25rem;

  @media screen and (min-width: bp(s)) {
    font-size: 1.5rem;
  }

  @media screen and (min-width: bp(m)) {
    font-size: 1.65rem;
  }
}

.userMenu {
  margin-left: auto;

  @media screen and (min-width: bp(s)) {
    width: span(3, 0, span(12));
    margin-left: span(0, 1);
  }
}

.user {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: auto;
  border-radius: 3px;

  &.active {
    background: rgba($color-purple, 0.1);
  }

  @media screen and (min-width: bp(s)) {
    margin-left: span(0, 1);
  }

  &:hover {
    .user__chevron {
      opacity: 1;
    }
  }
}

.user__icon {
  display: inline-block;
  width: 2rem;
  margin-right: 0.3em;
  font-size: 1.5rem;
}

.user__image {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.75em;
  object-fit: cover;
  background: white;
  border-radius: 1rem;
}

.user__name {
  display: none;
  overflow: hidden;
  color: var(--color-text-secondary);
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (min-width: bp(s)) {
    display: block;
  }
}

.user__chevron {
  margin-left: auto;
  color: var(--color-text-secondary);
  opacity: 0.5;
}

.menu {
  position: absolute;
  top: 3.5rem;
  right: 0;
  z-index: 100;
  width: 100%;
  padding: 0.5rem;
  background: white;
  border-radius: 3px;
  box-shadow: 0 3px 4px rgba($color-grey-500, 0.5);

  @media screen and (min-width: bp(xs)) {
    width: span(4);
  }

  @media screen and (min-width: bp(s)) {
    width: span(3);
  }

  .btn {
    width: 100%;
  }
}

.user {
  display: relative;
}

.menu__list {
  display: flex;
  flex-direction: column;
}

.menu__list-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.show-mobile {
  @media screen and (min-width: bp(m)) {
    display: none;
  }
}
